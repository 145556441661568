export interface TimeRemaining {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const ZERO_TIME_REMAINING: TimeRemaining = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
}

export function calculateTimeUntil(
  future: number,
  now: number = new Date().getTime()
): TimeRemaining {
  if (future < now) {
    return ZERO_TIME_REMAINING
  }
  let delta = Math.abs(future - now) / 1000

  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  // what's left is seconds
  const seconds = Math.floor(delta % 60)

  return {
    days,
    hours,
    minutes,
    seconds
  }
}
