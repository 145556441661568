import { H4, ImgIXRatio, StatementHero } from "@sixty-six-north/ui-system"
import { Flex } from "@theme-ui/components"
import { RichText } from "prismic-reactjs"
import React from "react"
import { htmlSerializer } from "../htmlSerializer"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import { linkResolver } from "../PrismicLinks"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"
import { PrismicImgixImage } from "./PrismicImgixImage"

export const FeatureSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const links = usePrismicLinks()

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex sx={{ flex: 1 }} id={data.primary.anchor_id}>
        {data.primary.image && (
          <StatementHero
            statement={adapter.statement("")}
            sentence={adapter.sentence("")}
            label={adapter.label("")}
            imageAlt={data.primary.image.alt || ""}
            hasGradientOverlay={data.primary.hasgradientoverlay}
            videoURL={adapter.videoURL("")}
            mark_as_h1={data.primary.mark_as_h1}
            imageNode={
              <PrismicImgixImage
                image={data.primary.image}
                aspectRatio={ImgIXRatio(adapter.ratio("16:9"))}
                sizes={"100vw"}
              />
            }
          >
            <Flex
              sx={{
                maxWidth: "48ch",
                flexDirection: "column"
              }}
            >
              <H4 sx={{ mb: 16 }}>{adapter.section_title()}</H4>
              <RichText
                render={data.primary.the_text}
                linkResolver={linkResolver(links)}
                htmlSerializer={htmlSerializer(links, data.primary.mark_as_h1)}
              />
            </Flex>
          </StatementHero>
        )}
      </Flex>
    </PrismicElementWrapper>
  )
}
