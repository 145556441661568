//jsxhook

import {
  TextVariants,
  H6,
  zIndices,
  StandardXAxisPadding,
  StandardXAxisSpace,
  MegaXAxisSpace,
  ButtonVariants
} from "@sixty-six-north/ui-system"
import React from "react"
import { Flex } from "theme-ui"
import { PrismicPrimaryAdapter, PrismicItemAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"

export const StickyHeader: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)

  return (
    <Flex
      sx={{
        position: "sticky",
        height: 64,
        top: [88, null, null, 0],
        zIndex: zIndices.stickyBars,
        bg: "blue.2",
        width: "100%",
        alignItems: "center"
      }}
    >
      <Flex
        sx={{
          flex: 1,
          alignItems: "center",
          pl: StandardXAxisPadding,
          pr: [16, 16, 48, 48, 48, StandardXAxisSpace]
        }}
      >
        <H6
          sx={{
            color: "white.0",
            mr: "auto"
          }}
        >
          {adapter.section_title()}
        </H6>

        {data.items
          .map(it => new PrismicItemAdapter(it))
          .map((it, idx) => (
            <a
              key={`btn-${idx}`}
              href={`#${it.anchorId()}`}
              sx={{ variant: `buttons.${ButtonVariants.tertiary}` }}
            >
              {it.label()}
            </a>
          ))}
      </Flex>
    </Flex>
  )
}
