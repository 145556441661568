//jsxhook
import { FontWeight, H6, radii, TextVariants } from "@sixty-six-north/ui-system"
import React from "react"
import { Box, Flex } from "theme-ui"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { PrismicElementWrapper } from "./Layout"

export const TextListSlice: React.FC<{ data: PrismicSlice }> = ({ data }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex
        sx={{
          flex: 1,
          p: 48,
          bg: "grey.4",
          flexDirection: "column",
          borderRadius: radii.clickable
        }}
      >
        <H6 sx={{ mb: 24, color: "black.1" }}>{adapter.section_title()}</H6>
        <dl
          sx={{
            columnCount: [1, null, null, 2],
            // @ts-ignore
            columnFill: "auto",
            // @ts-ignore
            columnGap: 48,
            "& *": {
              // @ts-ignore
              columnBreakInside: "avoid"
            }
          }}
        >
          {data.items.map((item, idx) => {
            const prismicItemAdapter = new PrismicItemAdapter(item)
            return (
              <Box
                key={`item-${idx}`}
                sx={{
                  mb: 24,
                  minHeight: 48
                }}
              >
                <dt
                  sx={{
                    color: "black.1",
                    variant: `text.${TextVariants.label2}`,
                    fontWeight: FontWeight.textBold
                  }}
                >
                  {prismicItemAdapter.question_title}
                </dt>
                <dd
                  sx={{
                    color: "grey.1",
                    m: 0,
                    maxWidth: "44ch",
                    variant: `text.${TextVariants.technicalParagraphSmall}`
                  }}
                >
                  {prismicItemAdapter.question_text}
                </dd>
              </Box>
            )
          })}
        </dl>
      </Flex>
    </PrismicElementWrapper>
  )
}
